angular.module('fingerink')
    .service('changeSignatureSignerModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function (signature, signer) {
            this.modalInstance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'signaturemodal',
                keyboard: false,
                controller: 'changeSignatureSignerModal',
                templateUrl: 'modals/changeSignatureSignerModal.tpl.html',
                controllerAs: 'controller',
                resolve:{
                    signature : ()=>signature,
                    signer: ()=>signer
                }
            });
            return this.modalInstance;
        };
    })
    .controller('changeSignatureSignerModal', function mostrarCtrl($q, $scope, $uibModalInstance, signatureService, swalService, signature, signer) {
        var that = this;
        that.signature = signature;
        that.signer = signer;

        that.newSigner = {name: signer.name, signatureEmail: signer.signatureEmail};
        that.cancel = () => $uibModalInstance.close();
        that.change = () => {
            that.loading = true;
            signatureService.changeSigner(signature.id, signer.id, that.newSigner).then(()=>{
                swalService.basicSwal('Correcto', 'El firmante se ha cambiado correctamente', 'success');
                $uibModalInstance.close();
                that.loading = false;
            }, ()=> {
                swalService.basicSwal('Vaya','Algo ha ido mal','error');
                that.loading = false;
            });
        };
    });

    